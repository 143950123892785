<template>
    <Sidebar ref="sidebarranovedades" v-model:visible="visiblesidenovedades" header="Novedades" position="top"
        @mouseleave="ocultarnovedades" style="height: 20rem;">
        <div class="direct-chat-messages" style="height: 100%;">
            <div class="direct-chat-msg" v-for="novedad in novedades" :key="novedad">
                <div class="direct-chat-text">
                    <i class="fas fa-info-circle"></i>&nbsp;
                    <a title="Novedades" @click="cambiarurl(novedad)"
                        href="#" v-html="novedad.titulo"></a>
                    &nbsp;<i @click="cerrarnovedad(novedad.id_novedad)" class="fa fa-window-close"></i>
                </div>
            </div>
        </div>
    </Sidebar>
    <form id="TheFormdos" method="get" :action="'https://' + direccionglobal" target="TheWindow">
    </form>
</template>
<script>
import Sidebar from 'primevue/sidebar';
import { PwgsApi } from '../services/PwgsApi';
export default ({
    props: ['novedades','visiblenovedades'],
    setup() {
        return;
    },
    components: {
        Sidebar
    },
    data() {
        return {
            visiblesidenovedades: false,
            urlnovedad: '',
            direccionglobal: 'https://' + localStorage.getItem('direccion'),
        }
    },
    methods: {
        async cerrarnovedad(id) {
            const api = new PwgsApi();
            try{
                await api.put('novedades-pwgs/' + id);
                this.$emit('actualizarNovedades');
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
        async ocultarnovedades(){
            this.visiblesidenovedades = false;
        },
        cambiarurl(novedad) {
            this.urlnovedad = this.direccionglobal + novedad.url_final;
            console.log("urlnovedad", this.urlnovedad);
            let aux = window.open('', 'TheWindow');
            document.getElementById('TheFormdos').submit();
            setTimeout(() => {
                aux.location.href = this.urlnovedad;

            }, 1000);
           
        

        },
     
    },
    watch: {
    visiblenovedades() {
        this.visiblesidenovedades = true;
    },
},
    mounted() {
        //this.direccionglobal = 'https://' + localStorage.getItem('direccion');
    },
})
</script>